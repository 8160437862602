
import { defineComponent } from "vue";
import pagination from 'vue-bootstrap-pagination'


export default defineComponent({
  name: "ovicoveragetransfertopimages", 
  methods: {
    
  },
  mounted(){
      
  },
  data(){
      return { 
          
      }
  }, 
  components: {
  },
  props: {
    widgetClasses: String
  }
});
