
import { defineComponent } from "vue";
import pagination from 'vue-bootstrap-pagination'
import {loadCoverageTransferList}  from "@/api/ovi_api";

import  SealProEncode from "@/views/modals/SealProEncode.vue"

import  SealProEmailActivation from "@/views/modals/SealProEmailActivation.vue"

export default defineComponent({
  name: "ovicoveragetransferlist", 
  methods: {
    async loadOVICoverageTransferList(){ 
      this.loading = true;
      const response = await loadCoverageTransferList();
       this.list = response.data.pageList;
      this.loading = false;
    },
    async showSealProApplicationForm(spcode, recid){
      this.loading = true;
        this.sealproencodekey = "sealproencodekey_" + recid;
        this.spcode = spcode;
        this.allowClose = true; 
        this.inputActivationDetail; 
        
        setTimeout(() => {  
              const btn:any = this.$refs.sealProEncodeModalButton;
              btn.click();
        }, 500);
      this.loading = false;
    },
    showEmailActivationForm(coverage){
      this.loading = true;
      this.emailto = coverage.email_to;
      this.fullname = coverage.email_fullname == null ? "" : coverage.email_fullname;
      this.contactno = coverage.contactno;
      this.coverage_id = coverage.recid;
      this.coverage_type = coverage.sealpro_type_desc;
        
      setTimeout(() => {  
            const btn:any = this.$refs.sealProEmailActivationModalButton;
            btn.click();
      }, 500);
      this.loading = false;
    }
    
  },
  mounted(){
      this.loadOVICoverageTransferList();
  },
  data(){
      return { 
          list : [],
          allowClose: true,
          inputActivationDetail: false,
          spcode: "",
          sealproencodekey: "",
          emailto: "",
          fullname:"",
          contactno: "",
          coverage_id: 0,
          coverage_type: "",
          loading: true,
      }
  }, 
  components: {
    SealProEncode,
    SealProEmailActivation
  },
  props: {
    widgetClasses: String
  }
});
