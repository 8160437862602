
import { defineComponent } from "vue"; 
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { coverage_send_activation} from "@/api/ovi_api";


export default defineComponent({
  name: "emailsealproactivation", 
  data(){
      return {  
        emailparam: "" as String,
        contactparam: "",
        fullnameparam: "" as String,
      }
  },
  methods: {    
    async sendActivation(){
      let data = {
        email: this.emailparam,
        fullname : this.fullnameparam,
        contactno: this.contactparam,
        coverage_id: this.coverage_id
      }
      const response = await coverage_send_activation(data);
      if(response.data.result === "FAILED") {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "error",
              confirmButtonClass: "btn btn-secondary"
            }); 
        return;
      }

      
      if(response.data.result === "SUCCESS") {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
              confirmButtonClass: "btn btn-success"
            }); 
            window.location.reload();
      }

    }
  }, 
  mounted(){   
    this.emailparam  = this.emailto + "";
    this.fullnameparam  = this.fullname + "";
    this.contactparam = this.contactno + "";
  },
  components: {},
  props: {
    widgetClasses: String,
    fullname: String,
    emailto: String,
    contactno: String,
    coverage_id: Number,
    coverage_type: String
  }
});
