
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import OVICoverageTransferList from "@/components/ovicoveragetransfer/OVICoverageTransferList.vue";
import OVICoverageTransferTopImages from "@/components/ovicoveragetransfer/OVICoverageTransferTopImages.vue";

export default defineComponent({
  name: "ovicoveragetransfer",
  components: {
    OVICoverageTransferList,
    OVICoverageTransferTopImages
  },
  data(){
    return {
      
    }
  },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("ovicoveragetransferTab") || "0");

    });
  }
});
